/**************
VARIABLES
***************/
.site-header.grid-container.fluid {
  background: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.home.section-one.grid-container.fluid {
  position: relative;
  padding: 0;
}
.home.section-one.grid-container.fluid .mask {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.home.section-one.grid-container.fluid .grid-x {
  height: 100vh;
  position: relative;
}
.home.section-one.grid-container.fluid .grid-x .txt {
  color: #fff;
  text-align: center;
}
.home.section-one.grid-container.fluid .grid-x .txt img {
  max-height: 30rem;
  margin-bottom: 5rem;
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .grid-x .txt img {
    max-height: 16rem;
  }
}
.home.section-one.grid-container.fluid .grid-x .txt h1 {
  margin-bottom: 0;
}
.home.section-one.grid-container.fluid .grid-x .txt h1, .home.section-one.grid-container.fluid .grid-x .txt h2, .home.section-one.grid-container.fluid .grid-x .txt h3, .home.section-one.grid-container.fluid .grid-x .txt h4, .home.section-one.grid-container.fluid .grid-x .txt p, .home.section-one.grid-container.fluid .grid-x .txt a {
  color: #fff;
}
.home.section-one.grid-container.fluid .grid-x .txt a.button1 {
  background: #fff;
  color: #121413;
  border-color: #fff;
}
.home.section-one.grid-container.fluid .grid-x .txt a.button1:after {
  color: #121413;
}
.home.section-one.grid-container.fluid .grid-x .txt a.button1:hover {
  background: #121413;
  color: #fff;
}
.home.section-one.grid-container.fluid .grid-x .txt a.button1:hover:after {
  color: #fff;
}
.home.section-one.grid-container.fluid .grid-x .bottom-bar {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 10rem;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-one.grid-container.fluid .grid-x .bottom-bar {
    padding: 1rem 5rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-one.grid-container.fluid .grid-x .bottom-bar {
    padding: 1rem 5rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .grid-x .bottom-bar {
    padding: 1rem;
  }
}
.home.section-one.grid-container.fluid .grid-x .bottom-bar .left a {
  color: #fff;
  margin-right: 0.5rem;
  width: auto;
}
.home.section-one.grid-container.fluid .grid-x .bottom-bar .left a svg {
  max-height: 1.8rem;
}
.home.section-one.grid-container.fluid .grid-x .bottom-bar .left a:hover {
  color: #abb7b0;
}
.home.section-one.grid-container.fluid .grid-x .bottom-bar .right {
  text-align: right;
}
.home.section-one.grid-container.fluid .grid-x .bottom-bar .right .catalogue-text-wrapper {
  display: inline-block;
  width: 18rem;
  height: 2rem;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .grid-x .bottom-bar .right .catalogue-text-wrapper {
    width: 15rem;
    height: 1rem;
  }
}
.home.section-one.grid-container.fluid .grid-x .bottom-bar .right .catalogue-text-wrapper .inner {
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
.home.section-one.grid-container.fluid .grid-x .bottom-bar .right .catalogue-text-wrapper .inner a {
  color: #fff;
  font-family: "Gotham Book";
  font-weight: 600;
  position: relative;
  display: block;
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .grid-x .bottom-bar .right .catalogue-text-wrapper .inner a {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
}
.home.section-one.grid-container.fluid .grid-x .bottom-bar .right .catalogue-text-wrapper:hover .inner {
  -webkit-transform: translateY(-2.7rem);
  transform: translateY(-2.7rem);
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .grid-x .bottom-bar .right .catalogue-text-wrapper:hover .inner {
    -webkit-transform: translateY(-1.2rem);
    transform: translateY(-1.2rem);
  }
}
.home.section-one.grid-container.fluid .grid-x #scroll-down {
  width: auto;
  position: absolute;
  bottom: -10rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 2rem;
  cursor: pointer;
}
.home.section-one.grid-container.fluid .grid-x #scroll-down span {
  width: 1px;
  display: block;
}
.home.section-one.grid-container.fluid .grid-x #scroll-down span:nth-child(1) {
  height: 12rem;
  background: #fff;
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
}
.home.section-one.grid-container.fluid .grid-x #scroll-down span:nth-child(2) {
  height: 8rem;
  background: #fff;
}
.home.section-one.grid-container.fluid .grid-x #scroll-down:hover span:nth-child(1) {
  height: 10rem;
}
.home.section-two.grid-container.fluid {
  position: relative;
  padding-top: 15rem;
  z-index: 1;
  background: #fff;
}
.home.section-two.grid-container.fluid .dark-block {
  background: #121413;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50rem;
  z-index: 0;
}
@media screen and (max-width: 39.9375em) {
  .home.section-two.grid-container.fluid .dark-block {
    height: 30rem;
  }
}
.home.section-two.grid-container.fluid .txt {
  text-align: center;
  margin-bottom: 5rem;
}
.home.section-two.grid-container.fluid .txt h3, .home.section-two.grid-container.fluid .txt p {
  color: #fff;
}
.home.section-two.grid-container.fluid .cell {
  padding: 1.5rem;
  z-index: 1;
}
@media screen and (max-width: 39.9375em) {
  .home.section-two.grid-container.fluid .cell {
    padding: 1rem 0;
  }
}
.home.section-two.grid-container.fluid .cell .img {
  min-height: 40rem;
  width: 100%;
  position: relative;
  display: block;
  border: 1px solid #F4F4F4;
  margin-bottom: 2rem;
}
@media screen and (max-width: 39.9375em) {
  .home.section-two.grid-container.fluid .cell .img {
    height: 25rem;
    min-height: 25rem;
  }
}
.home.section-two.grid-container.fluid .cell .img .inner {
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
  opacity: 0;
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.home.section-two.grid-container.fluid .cell .img:hover .inner {
  opacity: 1;
}
.home.section-two.grid-container.fluid .cell .title {
  text-align: center;
  margin: 1rem;
}
.home.section-three.grid-container.fluid {
  text-align: center;
  padding-top: 0;
  background: #fff;
}
.home.section-three.grid-container.fluid p {
  font-size: 1.8rem;
  line-height: 2.8rem;
}
.home.section-four.grid-container.fluid {
  background: #fff;
  padding-bottom: 0;
}
.home.section-four.grid-container.fluid .title {
  text-align: center;
}
.home.section-five.grid-container.fluid {
  background: #fff;
}
@media screen and (min-width: 64em) {
  .home.section-five.grid-container.fluid {
    padding-top: 5rem;
    padding-bottom: 15rem;
  }
}
.home.section-five.grid-container.fluid .item {
  padding: 3rem 2rem;
}
@media screen and (max-width: 39.9375em) {
  .home.section-five.grid-container.fluid .item {
    padding: 3rem 0;
  }
}
.home.section-five.grid-container.fluid .item .image {
  position: relative;
  margin-bottom: 2rem;
  text-align: center;
  display: block;
}
.home.section-five.grid-container.fluid .item .image img {
  max-height: 30rem;
  display: inline-block;
  width: auto;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-five.grid-container.fluid .item .image img {
    max-height: 15rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-five.grid-container.fluid .item .image img {
    max-height: 60rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-five.grid-container.fluid .item .image img {
    max-height: 60rem;
  }
}
.home.section-five.grid-container.fluid .item .top-terms {
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-transform: uppercase;
}
.home.section-five.grid-container.fluid .item .title {
  margin-bottom: 0.5rem;
  font-size: 2.2rem;
  line-height: 3.2rem;
}
.home.section-five.grid-container.fluid .item .bottom-terms {
  color: #727272;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.home.section-five.grid-container.fluid .item .button1 {
  margin-top: 1rem;
}
.home.section-six.grid-container.fluid {
  background: #121413;
  padding-top: 15rem;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-six.grid-container.fluid {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-six.grid-container.fluid {
    padding-top: 1rem;
  }
}
.home.section-six.grid-container.fluid .img {
  text-align: right;
}
@media screen and (max-width: 63.9375em) {
  .home.section-six.grid-container.fluid .img {
    text-align: left;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-six.grid-container.fluid .img {
    text-align: left;
  }
}
.home.section-six.grid-container.fluid .img .img-wrapper {
  position: relative;
  display: inline-block;
  width: 50rem;
  height: 60rem;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-six.grid-container.fluid .img .img-wrapper {
    width: 100%;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-six.grid-container.fluid .img .img-wrapper {
    width: 100%;
    height: auto;
  }
}
.home.section-six.grid-container.fluid .img .img-wrapper .img-item-1 {
  position: absolute;
  max-width: 30rem;
  top: 0;
  left: 0;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-six.grid-container.fluid .img .img-wrapper .img-item-1 {
    max-width: 40rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-six.grid-container.fluid .img .img-wrapper .img-item-1 {
    position: relative;
    max-width: 100%;
  }
}
.home.section-six.grid-container.fluid .img .img-wrapper .img-item-2 {
  position: absolute;
  max-width: 30rem;
  bottom: 10rem;
  right: 10rem;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-six.grid-container.fluid .img .img-wrapper .img-item-2 {
    max-width: 40rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-six.grid-container.fluid .img .img-wrapper .img-item-2 {
    position: relative;
    max-width: 100%;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-six.grid-container.fluid .img .img-wrapper .img-item-2 {
    right: 0;
    bottom: 5rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-six.grid-container.fluid .img .img-wrapper .img-item-2 {
    right: 0;
    margin-top: 1rem;
    bottom: auto;
  }
}
.home.section-six.grid-container.fluid .txt {
  padding: 0 0 10rem 10rem;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-six.grid-container.fluid .txt {
    padding: 0 0 10rem 20rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-six.grid-container.fluid .txt {
    padding: 3rem 0;
  }
}
.home.section-six.grid-container.fluid .txt h2 {
  margin-bottom: 5rem;
  color: #fff;
}
@media screen and (max-width: 39.9375em) {
  .home.section-six.grid-container.fluid .txt h2 {
    margin-bottom: 1rem;
  }
}
.home.section-six.grid-container.fluid .txt p, .home.section-six.grid-container.fluid .txt a {
  color: #fff;
}
.home.section-six.grid-container.fluid .txt .button1 {
  display: inline-block;
  margin: 3rem 0 0;
  color: #fff;
}
.home.section-six.grid-container.fluid .txt .button1:after {
  color: #fff;
}
.home.section-seven.grid-container.fluid {
  background: #F4F4F4;
  position: relative;
}
.home.section-seven.grid-container.fluid .dark-block {
  background: #121413;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50rem;
  z-index: 0;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-seven.grid-container.fluid .dark-block {
    height: 25rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-seven.grid-container.fluid .dark-block {
    height: 25rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-seven.grid-container.fluid .dark-block {
    height: 55rem;
  }
}
.home.section-seven.grid-container.fluid .txt {
  text-align: center;
}
.home.section-seven.grid-container.fluid .gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 5rem;
}
.home.section-seven.grid-container.fluid .gallery .slide-item {
  width: 25%;
  height: 25rem;
  overflow: hidden;
  position: relative;
  margin: 0;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-seven.grid-container.fluid .gallery .slide-item {
    height: 15rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-seven.grid-container.fluid .gallery .slide-item {
    height: 15rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-seven.grid-container.fluid .gallery .slide-item {
    width: 50%;
    height: 15rem;
  }
}
.home.section-seven.grid-container.fluid .gallery .slide-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}